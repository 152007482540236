<template lang="html">
  <div id="about-us-page" data-aos="fade">

    <section class="main-section">
      <div class="placed-backg box-image"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-xl-6 col-image"></div>

          <div class="col-lg-6 col-text" data-aos="fade-up">
            <h1 class="mb-2 title-s1 f-w-700 c-primary">Nosotros</h1>

            <!-- <h5 class="subtitle">x</h5> -->
            <p>
              Somos una empresa en desarrollo dedicada a la elaboración de laminas plásticas desde 2018,  tenemos la capacidad de brindarle calidad y servicio, ya que contamos con los mejores equipos para la fabricación de nuestros productos, así como con personal altamente capacitado, y materia prima de calidad.
            </p>

            <p class="mt-3">
              MICROCORRUGADOS PLÁSTICOS, ha crecido hasta convertirse en proveedor de importantes empresas en toda la república mexicana.
            </p>

            <p class="mt-3">
              Microcorrugados plásticos tiene el compromiso de establecer y mantener un sistema de evaluación continua en la calidad de las materias primas, como en nuestras laminas, proveyendo a nuestros clientes productos y servicios de calidad.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
