import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'home.vue').default },
      { path: '/nosotros', component: require(page+'about-us/index.vue').default, meta:{title:"Nosotros"}},
      { path: '/microcorrugados', component: require(page+'microcorrugados/index.vue').default, meta:{title:"Microcorrugados"}},
      { path: '/impresion', component: require(page+'impresion/index.vue').default, meta:{title:"Para impresión"}},
	    { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Microcorrugados Plasticos S.A. de C.V.", filter: (title)=>{ return title+" - Microcorrugados Plasticos S.A. de C.V."; } }
);

// export {routes};
export default MyRouter;
