<template lang="html">
  <div id="microcorrugados-page" data-aos="fade">

    <section class="main-section">
      <div class="placed-backg box-image"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-xl-6 col-image"></div>

          <div class="col-lg-6 col-text" data-aos="fade-up">
            <h1 class="mb-2 title-s1 f-w-700 c-primary">Microcorrugados</h1>

            <!-- <h5 class="subtitle">x</h5> -->
            <p>
              El nombre genérico para la lamina de plástico corrugado es MICROCORRUGADO.
            </p>

            <p class="mt-3">
              También conocido como coroplast. Es un material flexible, resistente y de fácil uso, el coroplast puede utilizarse para la elaboración de anuncios publicitarios, stands publicitarios, embalaje e impresión.
            </p>

            <p class="mt-3">
              Nos enfocamos en fabricar las laminas de microcorrugado trabajando siempre a la medida de nuestros clientes y de acuerdo a sus necesidades.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="vision-section">
      <div class="container oversized-container">
        <div class="box-information">
          <h2 class="text-center title-2">Lamina de plástico corrugado</h2>

          <p class="text-center mt-3">
            Microcorrugados plásticos tiene el compromiso de establecer y mantener un sistema de evaluación continua en la calidad de las materias primas, como en nuestras laminas, proveyendo a nuestros clientes productos y servicios de calidad.
          </p>

          <div class="row row-info">
            <div class="col-lg-4 col-info">
              <div class="b-image">
                <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/microcorrugados/image-1.jpg)' }"></div>
              </div>

              <h6 class="title">Lam = lamina</h6>
              <p>
                Laminas de microcorrugado para impresión, empaque o cualquier otro uso  (el color puede ser a solicitud del cliente.)
              </p>
            </div>

            <div class="col-lg-4 col-info">
              <div class="b-image">
                <div class="placed-backg image"  v-bind:style="{ backgroundImage: 'url(public/images/pages/microcorrugados/image-2.jpg)' }"></div>
              </div>

              <h6 class="title">Materias primas</h6>
              <p class="mb-2">
                PP: Polipropileno, resina con la que se fabrica el material.
              </p>
              <p>
                MASTERBATCH: Colorantes y aditivos que dan color al producto. (de acuerdo a los requerimientos del cliente)
              </p>
            </div>

            <div class="col-lg-4 col-info">
              <div class="b-image">
                <div class="placed-backg image"  v-bind:style="{ backgroundImage: 'url(public/images/pages/microcorrugados/image-3.jpg)' }"></div>
              </div>

              <h6 class="title">Dimensiones del producto</h6>
              <p>
                Primera dimensión: Grueso en milímetro.<br />
                Segunda dimensión: Ancho en centímetros.<br />
                Tercera dimensión: Largo en centímetros.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
