<template lang="html">
  <header id="header">
    <div class="box-content">
      <div class="box-top">
        <div class="container oversized-container">
          <div class="b-phones" data-aos="fade">
            <i class="fas fa-phone icon"></i>
            <span>(33) 1234 5678</span>
            <!-- <span>(33) 3135 3453</span> -->
          </div>
          <div class="b-email" data-aos="fade" data-aos-delay="50">
            <i class="fas fa-envelope icon"></i> <span>ventas@microcorrugadosplasticos.com</span>
          </div>
        </div>
      </div>

      <div class="box-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/" data-aos="fade" data-aos-delay="100">
              <img src="public/images/logo.svg">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"><i class="fas fa-bars"></i></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-item class="simple-item" data-aos="fade" data-aos-delay="150" to="/">Inicio</b-nav-item>
                <b-nav-item class="simple-item" data-aos="fade" data-aos-delay="200" to="/nosotros">Nosotros</b-nav-item>
                <b-nav-item class="simple-item" data-aos="fade" data-aos-delay="250" to="/microcorrugados">Microcorrugados</b-nav-item>
                <b-nav-item class="simple-item" data-aos="fade" data-aos-delay="300" to="/impresion">Impresión</b-nav-item>
                <b-nav-item class="simple-item" data-aos="fade" data-aos-delay="350" to="/contacto">Contacto</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
    </div>
  </header>
</template>

<script>
export default {
}
</script>
