<template lang="html">
  <footer id="footer">
		<div class="container oversized-container" data-aos="fade">
			<div class="row">
				<div class="col-md-6 col-lg col-text">
					<h5 class="title">Dirección</h5>
					<p>
						Av. de las Rosas #22, Jardines de la Calera,<br />
						Tlajomulco de Zúniga, Jalisco, México
					</p>
				</div>

				<div class="col-md-6 col-lg col-text">
					<h5 class="title">Contacto</h5>
					<p>
						<strong>Tel. </strong>
						<span>(33) 1234 5678</span>
						<!-- <span class="mx-2">|</span>
						<span>(33) 3135 3453</span> -->
					</p>

					<p class="mt-2">
						<strong>Email. </strong>
						<span>ventas@microcorrugadosplasticos.com</span>
					</p>
				</div>

				<div class="col-lg-3 col-logo">
					<img class="logo" src="public/images/logo-white.svg">
				</div>
			</div>
		</div>
  </footer>
</template>

<script>
export default {
}
</script>
