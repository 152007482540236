<template lang="html">
  <div id="home-page" data-aos="fade">

    <section class="container-fluid banner-section">
      <div v-swiper="bannersOptions" class="swiper swiper-desktop" v-if="banners.pc.length" :instanceName="'carrusel-bann1'" :loadtheme="false">
        <div class="swiper-wrapper">
          <div class="swiper-slide"  v-for="(banner, bdInx) in banners.pc" :key="'bdInx-'+bdInx">
            <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
              <img src="public/images/pages/home/banner.png">
            </a>
          </div>
        </div>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>

      <div v-swiper="bannersOptions" class="swiper swiper-mobile" v-if="banners.movil.length" :instanceName="'carrusel-bann2'" :loadtheme="false">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(banner, bmInx) in banners.movil" :key="'bmInx-'+bmInx">
            <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" :href="banner.url" >
              <img src="public/images/pages/home/banner-m.png">
            </a>
          </div>
        </div>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </section>

    <section class="about-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-info" data-aos="fade-right">
            <div class="box-image">
              <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/about-img-1.jpg)' }"></div>
            </div>
          </div>

          <div class="col-lg-8 col-info c2" data-aos="fade-left">
            <h5 class="title">Quienes somos</h5>

            <p>
              Somos una empresa en desarrollo dedicada a la elaboración de laminas plásticas desde 2018,  tenemos la capacidad de brindarle calidad y servicio, ya que contamos con los mejores equipos para la fabricación de nuestros productos, así como con personal altamente capacitado, y materia prima de calidad.
            </p>

            <p class="mt-2">
              MICROCORRUGADOS PLÁSTICOS, ha crecido hasta convertirse en proveedor de importantes empresas en toda la república mexicana.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: {
        pc: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg', url: '' },
        ],
        movil:[
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg', url: '' },
        ]
      },

      // == Carousels options ==
      bannersOptions: {
        slidesPerView: 1,
        loop: true,
        speed: 750,
        effect: 'fade',

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // == ==
    }
  }
}
</script>
