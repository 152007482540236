<template lang="html">
  <div id="impresion-page" data-aos="fade">

    <section class="main-section">
      <div class="placed-backg box-image"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-xl-6 col-image"></div>

          <div class="col-lg-6 col-text" data-aos="fade-up">
            <h1 class="mb-2 title-s1 f-w-700 c-primary">Impresión</h1>

            <!-- <h5 class="subtitle">x</h5> -->
            <p>
              Se utiliza un tratamiento por ambas caras, que ayuda a la buena absorción de adhesivos y tintas, a este tratamiento se le conoce como corona, siendo un material no toxico, no produce efecto negativo en la lamina de corrugado.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="vision-section">
      <div class="container" data-aos="fade">
        <div class="box-information">
          <h2 class="text-center title-2" data-aos="fade-up">Medidas y espesores</h2>

          <p class="text-center mt-1 p-md" data-aos="fade-up" data-aos-delay="300">
            Existen diferentes medidas, espesores y colores en nuestro producto.
          </p>

          <div class="table-responsive-md box-table" data-aos="fade-up" data-aos-delay="600">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">COLOR</th>
                  <th scope="col">ESPESOR</th>
                  <th scope="col">KG/M2</th>
                  <th scope="col">HOJA</th>
                  <th scope="col">PZS POR TARIMA</th>
                  <th scope="col">PESO POR TARIMA</th>
                  <th scope="col">DIMENSIÓN POSIBLE</th>
                  <th scope="col">DIMENSIÓN POSIBLE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>BLANCO</td>
                  <td>2.0 MM</td>
                  <td>0.430</td>
                  <td>1.29</td>
                  <td>500</td>
                  <td>680</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>NEGRO</td>
                  <td>2.0 MM</td>
                  <td>0.430</td>
                  <td>1.29</td>
                  <td>500</td>
                  <td>680</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>BLANCO</td>
                  <td>3.0 MM</td>
                  <td>0.56</td>
                  <td>1.68</td>
                  <td>330</td>
                  <td>589</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>NEGRO</td>
                  <td>3.0 MM</td>
                  <td>0.56</td>
                  <td>1.68</td>
                  <td>330</td>
                  <td>589</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>BLANCO</td>
                  <td>4.0 MM</td>
                  <td>0.68</td>
                  <td>2.04</td>
                  <td>250</td>
                  <td>545</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>NEGRO</td>
                  <td>4.0 MM</td>
                  <td>0.68</td>
                  <td>2.04</td>
                  <td>250</td>
                  <td>545</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>BLANCO</td>
                  <td>5.0 MM</td>
                  <td>1.00</td>
                  <td>3</td>
                  <td>200</td>
                  <td>635</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>NEGRO</td>
                  <td>5.0 MM</td>
                  <td>1.00</td>
                  <td>3</td>
                  <td>200</td>
                  <td>635</td>
                  <td>122</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>BLANCO</td>
                  <td>6.0 MM</td>
                  <td>1.300</td>
                  <td>3.9</td>
                  <td>200</td>
                  <td>815</td>
                  <td>122</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>NEGRO</td>
                  <td>6.0 MM</td>
                  <td>1.300</td>
                  <td>3.9</td>
                  <td>200</td>
                  <td>815</td>
                  <td>122</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>BLANCO</td>
                  <td>8.0 MM</td>
                  <td>1.800</td>
                  <td>5.4</td>
                  <td>180</td>
                  <td>1007</td>
                  <td>122</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>NEGRO</td>
                  <td>8.0 MM</td>
                  <td>1.800</td>
                  <td>5.4</td>
                  <td>180</td>
                  <td>1007</td>
                  <td>122</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>BLANCO</td>
                  <td>10.0 MM</td>
                  <td>2.200</td>
                  <td>6.6</td>
                  <td>150</td>
                  <td>1025</td>
                  <td>122</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>NEGRO</td>
                  <td>10.0 MM</td>
                  <td>2.200</td>
                  <td>6.6</td>
                  <td>150</td>
                  <td>1025</td>
                  <td>122</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
